@font-face {
    font-family: "Lexend";
    src: url("../src/assets/fonts/Lexend-VariableFont_wght.ttf") format("truetype");
    font-weight: 100 700;
}

@font-face {
    font-family: "PlusJakartaSans";
    src: url("../src/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
    font-weight: 100 700;
}

html {
    scroll-behavior: smooth;
}

.App {
    background-color: black;
}